<script>
import "../../assets/common/common.css";
import {
  getPartnerMeetingProducts,
  removeProductInMeeting,
  updatePartnerProduct,
} from "../../api/api";
import { getUserId } from "../../utils/store";
import { editorOption } from "@/constants";
const defaultValue = {
  product_id: "",
  product_name: undefined,
  product_intro: undefined,
};
/** 品牌产品 */
export default {
  name: "index",
  data() {
    return {
      meeting_id: this.$route.query.meeting_id,
      dataList: [],
      showAddOrUpdateDialog: false,
      exhibitorInfo: {
        id: "",
        booth: "",
        view_points: "",
      },
      user_id: getUserId(),
      editorOption,
      productInfo: Object.assign({}, defaultValue),
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    /** 获取指定会议、品牌下的产品 */
    fetchData() {
      getPartnerMeetingProducts({
        meeting_id: this.meeting_id,
        partner_id: this.user_id,
      }).then((res) => {
        this.dataList = res.data.data;
      });
    },
    doShowAddOrUpdateDialog() {
      this.productInfo = Object.assign({}, defaultValue);
      this.showAddOrUpdateDialog = true;
    },
    onEdit(row) {
      this.productInfo = {
        product_id: row.product_id,
        product_name: row.product_info.product_name,
        product_intro: row.product_info.product_intro,
      };
      this.showAddOrUpdateDialog = true;
    },
    onRemove(row) {
      this.$alert("从会议中删除此产品？", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            removeProductInMeeting({
              meeting_id: this.meeting_id,
              product_id: row.product_id
            }).then((res) => {
              this.$message.success("删除成功");
              this.fetchData();
            });
          }
        },
      });
    },
    saveContent() {
      updatePartnerProduct({
        ...this.productInfo,
        meeting_id: this.meeting_id,
        partner_id: this.user_id,
      }).then((res) => {
        this.$message.success(`${this.productInfo.product_id ? '更新' : '增加'}成功`);
        this.fetchData();
        this.showAddOrUpdateDialog = false;
      });
    },
  },
};
</script>

<template>
  <div class="view-root flex-column">
    <div
      style="display: flex; justify-content: space-between; align-items: center"
    >
      <p class="common-title">品牌产品</p>
      <p class="btn-wrap-width" @click="doShowAddOrUpdateDialog">新增</p>
    </div>
    <div class="table-margin-top flex-1-overflow-y">
      <div class="table-header table-margin-bottom">
        <div class="table-index">序号</div>
        <div class="table-booth">产品名称</div>
        <div class="table-view_points">产品介绍</div>
         <div class="table-operation">操作</div>
      </div>
      <div
        v-for="(item, index) in dataList"
        :key="index"
        class="table-item table-margin-bottom"
      >
        <div class="table-index">{{ index + 1 }}</div>
        <div class="table-booth">
          {{ item.product_info.product_name }}
        </div>
        <div class="table-view_points" v-html="item.product_info.product_intro"></div>
        <div class="table-operation" style="display: flex">
          <p
            class="list-btn-blue"
            style="text-align: left; padding-left: 8px"
            @click="onEdit(item)"
          >
            编辑
          </p>
          <p
            class="list-btn-red"
            style="margin-left: 10px; text-align: left; padding-left: 8px"
            @click="onRemove(item)"
          >
            删除
          </p>
        </div>
      </div>
    </div>
    <!-- 品牌产品编辑弹窗 -->
    <el-dialog
      :visible.sync="showAddOrUpdateDialog"
      :title="productInfo.product_id == '' ? '添加品牌产品' : '编辑品牌产品'"
      width="65%"
    >
      <div>
        <div>
          <el-form :model="productInfo">
            <el-form-item label="名称" prop="name">
              <el-input
                v-model="productInfo.product_name"
                placeholder="请输入"
              ></el-input>
            </el-form-item>
            <el-form-item label="">
              <label>介绍</label>
              <quill-editor
                class="ql-container"
                style="margin-bottom: 20px"
                ref="myTextEditor"
                v-model="productInfo.product_intro"
                :options="editorOption"
              >
              </quill-editor>
            </el-form-item>
          </el-form>
        </div>
        <div style="text-align: right; margin-top: 100px">
          <span slot="footer" class="dialog-footer">
            <el-button @click="showAddOrUpdateDialog = false">取 消</el-button>
            <el-button type="primary" @click="saveContent()">确 定</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss" scoped>
.table-name {
  flex: 2;
}

.table-booth {
  flex: 2;
}

.table-view_points {
  flex: 2;
}

.table-logo {
  flex: 2;
}

.table-operation {
  flex: 1.5;
}

.ql-container {
  height: 160px !important;
}

.logo-uploader {
  width: 178px;
  height: auto;
  object-fit: cover;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
}

.logo-uploader2 {
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
  font-size: 28px;
  color: #8c939d;
  object-fit: cover;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
}
</style>
